import {asyncActionError, asyncActionFinish, asyncActionStart} from "../../async/AsyncActions";
import {
    ADD_POSTS,
    NEW_POSTS_FETCH_SUCCESS,
    POPULAR_POSTS_FETCH_SUCCESS,
    SET_SCROLL_POSITION,
    TRENDING_POSTS_FETCH_SUCCESS
} from "../../../actions/types";
import {fetcher} from "../../../utilities/utils";
import * as Sentry from "@sentry/node";

export const setScrollPosition = scrollPosition => async (dispatch) => {
    dispatch({
        type: SET_SCROLL_POSITION,
        value: scrollPosition
    });
};

export const getTrendingPosts = (isRefresh) => async (dispatch, getState) => {

    try {
        dispatch(asyncActionStart());
        let lastVisibleCursor = getState().home.lastCursorTrending;
        const baseUrl = 'https://naija-world-1c442.web.app/api/trendingPosts'
        const url = isRefresh ? baseUrl : `${baseUrl}?cursor=${lastVisibleCursor}`;

        const response = await fetcher(url) || [];
        const {data: posts, nextCursor: cursor} = response;

        if (posts.length > 0) {
            dispatch({type: ADD_POSTS, payload: posts});
            dispatch({type: TRENDING_POSTS_FETCH_SUCCESS, cursor});
        }
        dispatch(asyncActionFinish());
    } catch (error) {
        Sentry.captureException(error);
        dispatch(asyncActionError());
    }
};


export const getNewPosts = (isRefresh) => async (dispatch, getState) => {

    try {
        dispatch(asyncActionStart());
        let lastVisibleCursor = getState().home.lastCursorNew;
        const baseUrl = 'https://naija-world-1c442.web.app/api/newPosts'
        const url = isRefresh ? baseUrl : `${baseUrl}?cursor=${lastVisibleCursor}`;

        const response = await fetcher(url) || [];
        const {data: posts, nextCursor: cursor} = response;

        if (posts.data.length > 0) {
            dispatch({type: ADD_POSTS, payload: posts});
            dispatch({type: NEW_POSTS_FETCH_SUCCESS, cursor});
        }
        dispatch(asyncActionFinish());
    } catch (error) {
        Sentry.captureException(error);
        dispatch(asyncActionError());
    }
};

export const getPopularPosts = (isRefresh) => async (dispatch, getState) => {

    try {
        dispatch(asyncActionStart());
        let lastVisibleCursor = getState().home.lastCursorPopular;
        const baseUrl = 'https://naija-world-1c442.web.app/api/popularPosts'
        const url = isRefresh ? baseUrl : `${baseUrl}?cursor=${lastVisibleCursor}`;

        const response = await fetcher(url) || [];
        const {data: posts, nextCursor: cursor} = response;

        if (posts.length > 0) {
            dispatch({type: ADD_POSTS, payload: posts});
            dispatch({type: POPULAR_POSTS_FETCH_SUCCESS, cursor});
        }
        dispatch(asyncActionFinish());
    } catch (error) {
        Sentry.captureException(error);
        dispatch(asyncActionError());
    }
};