import React from 'react';
import {Button, Icon} from "semantic-ui-react";
import isEmpty from 'lodash/isEmpty';
import {ListGroup} from "react-bootstrap";
import {approx, timeSince} from "../../utilities/utils";
import Link from "next/link";
import {useDispatch} from "react-redux";
import {readPost, toggleDislikePost, toggleLikePost} from "../../screens/main/postDetails/PostDetailsActions";
import Image from 'next/image'


import 'semantic-ui-css/components/button.min.css'
import 'semantic-ui-css/components/icon.min.css'
import {useUser} from "../../context/userContext";

const PostItem = ({key, post}) => {
    const {userId} = useUser();
    const dispatch = useDispatch();
    let {
        title,
        groupName,
        timeStamp,
        diffVoteCnt,
        id,
        isGraphic,
        isProfane,
        youtubeIds,
        thumbnail,
        isRead,
        dislikers,
        likers,
        video,
        commentCnt
    } = post;
    let isGraphicOrProfane = isGraphic || isProfane;

    if (!Boolean(thumbnail) && !isEmpty(youtubeIds)) {
        thumbnail = `https://i.ytimg.com/vi/${youtubeIds[0]}/default.jpg`
    }
    return (
        <ListGroup.Item key={key} onClick={() => dispatch(readPost(id))}
                        className={isRead ? "text-secondary p-2" : 'p-2'}>
            <Link href="/p/[id]" as={`/p/${id}`}>
                <div className={
                    'd-flex justify-content-between'
                } style={{minHeight: 90}}>
                    <div className={'d-flex flex-column justify-content-between flex-fill'}>
                        <div>
                            <h6 className={'m-0'} style={{fontSize: 14}}>{title}</h6>
                            <div className={'d-flex flex-row py-1 text-secondary'} style={{fontSize: 12}}>
                                <div>
                                    {groupName && groupName} ∙ {timeSince(new Date(timeStamp))}
                                </div>
                                {isGraphicOrProfane &&
                                    <div className={'ml-1'}> ∙ 🔞</div>}
                            </div>
                        </div>
                        <div className={'d-flex flex-row justify-content-between align-items-center'}>
                            <div className={'d-flex flex-row justify-content-between align-items-center'}
                                 style={{width: '110px'}}>
                                <Button icon={'thumbs up'}
                                        onClick={() => toggleLikePost(post, userId)}
                                        color={likers.includes(userId) ? 'green' : null} circular size={'mini'}/>
                                <div style={{fontSize: 14}}>{approx(diffVoteCnt, 1)}</div>
                                <Button icon={'thumbs down'} color={dislikers.includes(userId) ? 'red' : null}
                                        onClick={() => toggleDislikePost(post, userId)} circular size={'mini'}/>
                            </div>
                            {
                                commentCnt &&
                                <div className={'d-flex flex-row'}>
                                    <div className={'pr-1'}>{commentCnt}</div>
                                    <Icon name={'comments'} size={"large"}/>
                                </div>
                            }
                        </div>
                    </div>
                    {Boolean(thumbnail) &&
                        <div style={{height: 90, position: 'relative', flex: '0 0 90px'}}
                             className={"ml-3 pull-right align-self-center"}>
                            <Image
                                layout={"fill"}
                                src={thumbnail}
                                blurDataURL="data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAIAAoDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAb/xAAhEAACAQMDBQAAAAAAAAAAAAABAgMABAUGIWEREiMxUf/EABUBAQEAAAAAAAAAAAAAAAAAAAMF/8QAGhEAAgIDAAAAAAAAAAAAAAAAAAECEgMRkf/aAAwDAQACEQMRAD8AltJagyeH0AthI5xdrLcNM91BF5pX2HaH9bcfaSXWGaRmknyJckliyjqTzSlT54b6bk+h0R//2Q=="
                                className={'rounded'}
                                alt="thumbnail"
                                // style={isGraphicOrProfane ? {filter: 'blur(2px)'} : null}
                                placeholder="blur"
                            />
                            {
                                (!isEmpty(youtubeIds) || !isEmpty(video)) &&
                                <Icon name={'play'} color={'red'} inverted style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                }} bordered size={'tiny'}/>
                            }
                        </div>
                    }
                </div>
            </Link>
        </ListGroup.Item>
    )
}

export default React.memo(PostItem)