import React, {Fragment} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PostItem from "./PostItem";
import {ListGroup} from "react-bootstrap";
import {Loader} from "semantic-ui-react";
import isEmpty from 'lodash/isEmpty'

import 'semantic-ui-css/components/loader.min.css'

const PostList = ({posts, getNextPosts, loading, hasMore, emptyMessage}) => {
    if (isEmpty(posts)) {
        if (loading) {
            return (
                <Fragment>
                    <Loader content='Loading...'/>
                </Fragment>
            )
        } else {
            return (
                <h3 className={'d-flex justify-content-center align-items-center'}>{emptyMessage}</h3>
            )
        }
    } else {
        return (
            <Fragment>
                <InfiniteScroll
                    dataLength={posts.length}
                    next={getNextPosts}
                    hasMore={hasMore}
                    loader={<h2>Loading...</h2>}
                >
                    <ListGroup>
                        {posts.map(post => (
                            <PostItem key={post.id} post={post}/>
                        ))}
                    </ListGroup>
                </InfiniteScroll>
            </Fragment>
        )
    }
}

export default PostList;

